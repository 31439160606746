/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../domElementGetters";
import { CustomerAdministration, CustomerAdministrationRootProps } from "@gkuis/gkp-customeradministration-ui";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { EnvConfig } from "../types/EnvConfig";

export function configureApplicationCustomerAdministration(envConfig: EnvConfig) {
  CustomerAdministration.config = {
    baseUri: process.env.CUSTOMER_ADMINISTRATION_BASE_URI,
    ccOrderBaseUri: process.env.CC_ORDER_BASE_URI,
    imageUri: process.env.CUSTOMER_ADMINISTRATION_IMAGE_BASE_URI,
    cmsContentRoot: envConfig.cmsContentRoot
  };
}

export function registerApplicationCustomerAdministration(authenticationManager: AuthenticationManager) {
  const customerAdministrationRootProps: CustomerAdministrationRootProps = {
    authenticationManager
  };

  registerApplication({
    name: "@gkuis/gkp-customeradministration-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-customeradministration-ui"),
    activeWhen: ["/customeradministration"],
    customProps: {
      ...customerAdministrationRootProps,
      domElementGetter: reactDomElementGetter
    },
  });
}