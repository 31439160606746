/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { OnlineCustomerAcquisition, OnlineCustomerAcquisitionRootProps } from "@gkuis/gkp-onlinecustomeracquisition-ui";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../domElementGetters";
import { EnvConfig } from "../types/EnvConfig";

export function configureApplicationOnlineCustomerAcquisition(envConfig: EnvConfig) {
  OnlineCustomerAcquisition.config = {
    baseUri: process.env.ONLINE_CUSTOMER_ACQUISITION_BASE_URI,
    customerAdministrationBaseUri: process.env.CUSTOMER_ADMINISTRATION_BASE_URI,
    imageUri: process.env.ONLINE_CUSTOMER_ACQUISITION_IMAGE_BASE_URI,
    documentUri: process.env.ONLINE_CUSTOMER_ACQUISITION_DOCUMENT_BASE_URI,
    userBaseUri: process.env.USER_EXTERNAL_BASE_URI,
    externalFrameBaseUrl: "doesnotmakesense",
    cmsContentRoot: envConfig.cmsContentRoot
  };
}

export function registerApplicationOnlineCustomerAcquisition(authenticationManager: AuthenticationManager) {
  const onlineCustomerAcquisitionRootProps: OnlineCustomerAcquisitionRootProps = {
    authenticationManager
  };

  registerApplication({
    name: "@gkuis/gkp-onlinecustomeracquisition-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-onlinecustomeracquisition-ui"),
    activeWhen: ["/onlinecustomeracquisition"],
    customProps: {
      ...onlineCustomerAcquisitionRootProps,
      domElementGetter: reactDomElementGetter
    }
  });
}