/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { Billing, BillingRootProps } from "@gkuis/gkp-billing-ui";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../domElementGetters";
import { EnvConfig } from "../types/EnvConfig";

export function configureApplicationBilling(envConfig: EnvConfig) {
  Billing.config = {
    baseUri: process.env.BILLING_BASE_URI,
    cmsContentRoot: envConfig.cmsContentRoot,
  };
}

export function registerApplicationBilling(authenticationManager: AuthenticationManager) {
  const billingRootProps: BillingRootProps = {
    authenticationManager
  };

  registerApplication({
    name: "@gkuis/gkp-billing-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-billing-ui"),
    activeWhen: ["/billing"],
    customProps: {
      ...billingRootProps,
      domElementGetter: reactDomElementGetter
    },
  });
}