/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { Responseplus, ResponseplusRootProps } from "@gkuis/gkp-responseplus-ui";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../domElementGetters";

export function configureApplicationResponseplus() {
  Responseplus.configure({
    resourceDataBaseUri: `${process.env.RESPONSEPLUS_BASE_URI}/v1`,
    validationRulesBaseUri: `${process.env.RESPONSEPLUS_BASE_URI}/v1`,
    productsUri: `${process.env.RESPONSEPLUS_BASE_URI}/v1/responseplus/products`,
    frankingPreviewUri: `${process.env.RESPONSEPLUS_BASE_URI}/v1/responseplus/order/frankingPreview`,
    orderUri: `${process.env.RESPONSEPLUS_BASE_URI}/v1/responseplus/order`,
    customerProfilesUri: `${process.env.RESPONSEPLUS_BASE_URI}/v1/responseplus/user/customerProfiles`,
    contractIdUri: `${process.env.RESPONSEPLUS_BASE_URI}/v1/responseplus/user/contractID`,
    imageUri: process.env.RESPONSEPLUS_IMAGE_BASE_URI
  });
}

export function registerApplicationResponseplus(authenticationManager: AuthenticationManager) {
  const responseplusRootProps: ResponseplusRootProps = {
    authenticationManager,
    responseplusConfiguration: Responseplus.configure()
  };

  registerApplication({
    name: "@gkuis/gkp-responseplus-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-responseplus-ui"),
    activeWhen: ["/responseplus"],
    customProps: {
      ...responseplusRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}