/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CcReportingCo2 } from "@gkuis/gkp-customerconfigreportingco2-ui";

export function configureApplicationCcReportingCo2() {
  CcReportingCo2.config = {
    baseUri: process.env.CCREPORTINGCO2_BASE_URI
  };
}
