/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { RootProps } from "@gkuis/gkp-content-ui";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { EnvConfig } from "../types/EnvConfig";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../domElementGetters";
import { UserExternal } from "@gkuis/gkp-benutzer-ui";

export const registerApplicationContent = (authenticationManager: AuthenticationManager, envConfig: EnvConfig) => {
  const contentRootProps: RootProps = {
    mode: "external",
    authenticationManager: authenticationManager,
    cmsContentRoot: envConfig.cmsContentRoot,
    inquiryRequestUrl: envConfig.inquiryRequestUrl,
    inquiryRequestCollectiveSubmissionUrl: envConfig.inquiryRequestCollectiveSubmissionUrl,
    userExternalBaseUri: `${UserExternal.config.baseUri}/v1`
  };

  registerApplication({
    name: "@gkuis/gkp-content-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-content-ui"),
    activeWhen: ["/content"],
    customProps: {
      ...contentRootProps,
      domElementGetter: reactDomElementGetter
    }
  });
};