/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { RootProps } from "@gkuis/gkp-footer-ui";
import { getFooterNavigation } from "../navigation";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { EnvConfig } from "../types/EnvConfig";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../domElementGetters";
import { TrackingManager } from "../TrackingManager";

export function registerApplicationFooter(
    authenticationManager: AuthenticationManager,
    envConfig: EnvConfig,
    trackingManager: TrackingManager | undefined
) {
  const footerRootProps: RootProps = {
    authenticationManager: authenticationManager,
    navigation: getFooterNavigation(envConfig, () => trackingManager?.loadAndShowConsentDialog()),
    languageSwitchUrl: process.env.LANGUAGE_SWITCH_URL
  };

  registerApplication({
    name: "@gkuis/gkp-footer-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-footer-ui"),
    activeWhen: ["/"],
    customProps: {
      ...footerRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}
