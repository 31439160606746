/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CcOrderManagementMail } from "@gkuis/gkp-customerconfigordermanagementmail-ui";

export function configureApplicationCcOrderManagementMail() {
  CcOrderManagementMail.config = {
    baseUri: process.env.CCORDERMANAGEMENTMAIL_BASE_URI,
  };
}
