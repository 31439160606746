/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { trackPageCall } from "@gkuis/gkp-base-widgets";
import { LifeCycles, registerApplication, SingleSpaCustomEventDetail } from "single-spa";
import { angularDomElementGetterFactory } from "../domElementGetters";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { EpmTypes } from "@epm/types";

export function registerApplicationEPostMailer(authenticationManager: AuthenticationManager) {
  window.addEventListener("single-spa:routing-event", (evt: CustomEvent<SingleSpaCustomEventDetail>) => {
    const newPath = new URL(evt.detail.newUrl).pathname;
    [
      {path: "/mailer/journal", pageName: "Brief:Versenden Brief E-Post-Mailer:Journal"},
      {path: "/mailer/basket", pageName: "Brief:Versenden Brief E-Post-Mailer:Sammelkörbe"},
      {path: "/mailer/printer", pageName: "Brief:Versenden Brief E-Post-Mailer:Drucker"},
      {path: "/mailer/attachment", pageName: "Brief:Versenden Brief E-Post-Mailer:Anhänge"},
      {path: "/mailer/letterhead", pageName: "Brief:Versenden Brief E-Post-Mailer:Briefpapier"},
      {path: "/mailer/settings/organization", pageName: "Brief:Versenden Brief E-Post-Mailer:Unternehmenseinstellungen"},
      {path: "/mailer/settings/editor", pageName: "Brief:Versenden Brief E-Post-Mailer:Nutzereinstellungen"}
    ]
        .filter(({path}) => newPath.startsWith(path))
        .forEach(({pageName}) => trackPageCall(pageName));
  });

  const ePostMailerRootProps: EpmTypes = {
    authenticationManager
  }

  registerApplication({
    name: "@epm",
    app: () => System.import<LifeCycles>("@epm"),
    activeWhen: ["/mailer"],
    customProps: {
      ...ePostMailerRootProps,
      domElementGetter: angularDomElementGetterFactory("@epm")
    },
  });
}
