/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CcElectronicRegistrationOfRegisteredMail } from "@gkuis/gkp-customerconfigelectronicregistrationofregisteredmail-ui";
import { EnvConfig } from "../types/EnvConfig";

export function configureApplicationCcElectronicRegistrationOfRegisteredMail(envConfig: EnvConfig) {
  CcElectronicRegistrationOfRegisteredMail.config = {
    baseUri: process.env.CCELECTRONICREGISTRATIONOFREGISTEREDMAIL_BASE_URI,
    cmsContentRoot: envConfig.cmsContentRoot
  };
}