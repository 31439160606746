/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { LifeCycles, registerApplication } from "single-spa";
import { angularDomElementGetterFactory } from "../domElementGetters";
import { PrintMailingSingleSpaProps } from "@print-mailing-planner/frontend-printmailing";

export function registerApplicationPrintMailingPlanner(authenticationManager: AuthenticationManager) {
  const rootProps: PrintMailingSingleSpaProps = {
    authenticationManager
  };

  registerApplication({
    name: "@print-mailing-planner/frontend-printmailing",
    app: () => System.import<LifeCycles>("@print-mailing-planner/frontend-printmailing"),
    activeWhen: ["/planen"],
    customProps: {
      ...rootProps,
      domElementGetter: angularDomElementGetterFactory("@print-mailing-planner/frontend-printmailing")
    },
  });
}