/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { RegisterApplicationConfig } from "single-spa";

const domElementGetter = (name: RegisterApplicationConfig<unknown>["name"]) => document.getElementById(`single-spa-application:${name}`);

export const reactDomElementGetter = ({name}: Pick<RegisterApplicationConfig<unknown>, "name">) => domElementGetter(name);

export const angularDomElementGetterFactory = (name: RegisterApplicationConfig<unknown>["name"]) => () => domElementGetter(name);