/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { UserExternal, UserExternalRootProps } from "@gkuis/gkp-benutzer-ui";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../domElementGetters";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { envConfig } from "../../test-utils/envConfig";

export function configureApplicationUserExternal() {
  UserExternal.config = {
    baseUri: process.env.USER_EXTERNAL_BASE_URI,
    documentUri: process.env.USER_EXTERNAL_DOCUMENT_BASE_URI,
    cmsContentRoot: envConfig.cmsContentRoot
  };
}

export function registerApplicationUserExternal(authenticationManager: AuthenticationManager) {
  const userExternalRootProps: UserExternalRootProps = {
    authenticationManager
  };

  registerApplication({
    name: "@gkuis/gkp-benutzer-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-benutzer-ui"),
    activeWhen: [
      "/user",
      "/ordermanagement-mail",
      "/reporting",
      "/customeradministration/externaluseradministration",
      "/customeradministration/createexternaluser",
      "/customeradministration/twofactorauthentication"
    ],
    customProps: {
      ...userExternalRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}
